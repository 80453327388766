/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { Box, SxProps } from '@mui/system';
import { Theme } from '@mui/system/createTheme';
import { CircularProgress } from '@mui/material';

interface Props {
  children: React.ReactNode,
  sx?: SxProps<Theme>,
  nextPage: () => void,
  loading: boolean,
  callNextPage?: boolean,
}

const OvPagination = ({
  children,
  sx,
  nextPage,
  loading,
  callNextPage,
} : Props): JSX.Element => {
  const ref = useRef<HTMLElement | null>(null);

  const handleScroll = async (e: any): Promise<void> => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 2);
    if (bottom) {
      nextPage();
    }
  };

  const loadSecondPage = () => {
    if (ref.current) {
      const callNext = (window.innerHeight - (ref.current.offsetParent?.clientHeight ?? 0)) > 165;
      if (callNext) {
        nextPage();
      }
    }
  };

  useEffect(() => {
    if (callNextPage) loadSecondPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callNextPage, ref.current]);

  return (
    <Box sx={sx} onScroll={((event) => handleScroll(event))} ref={ref}>
      {children}
      {
        loading ? (
          <Box display="flex" alignSelf="center" justifyContent="center" mt={2}>
            <CircularProgress data-testid="loading-element" />
          </Box>
        ) : undefined
      }
    </Box>
  );
};

OvPagination.defaultProps = {
  sx: undefined,
  callNextPage: false,
};
export default OvPagination;
