/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Container,
  Tab,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  ToggleButton,
  Button,
  Popover, CircularProgress,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  TabPanel, TabContext, TabList,
} from '@mui/lab';
import React, {
  FormEvent, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { isNil } from 'lodash';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { styles } from './style';
import {
  FETCH_ACCOUNT_STATEMENT,
  FETCH_ACCOUNT_STATEMENTS,
  FETCH_ALL_LATEST_FORM_AGREEMENT, FETCH_FILE_DOCUMENT,
  FETCH_FILE_DOCUMENTS,
  RENDER_FORM_AGREEMENT,
} from './graphql';
import DialogPopUp from './dialog';
import OvPagination from '../common/wrappers/ovPagination';
import { allAccountTypesVar, availableFeatureFlagsVar, userIdVar } from '../../utils/localVariables';
import { translateBackend } from '../../assets/i18n/config';
import { FeatureFlagTypes, isFeatureEnabled } from '../account/resources';
import { useContextTheme } from '../../providers/contextThemeProvider';
import FilterIcon from '../../assets/images/filter.svg';
import FlowModal from '../common/wrappers/modals/ovFlowModal';
import OvForm from '../common/wrappers/ovForm';
import DroppableFileInput, { FileTypes } from '../flinks/droppableFileInput';
import SimpleInput from '../common/inputs/simpleInput';
import {
  CreateFileInput, FileDocumentObjectTypes, FileDocumentTypes, FileUploaderProps, fileUploader,
} from '../../utils/fileUploader';
import { formatDate } from '../../utils/commonMethods';
import FilterDocuments from './filterDocuments';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';

interface Props {
  onClose: () => void,
}

interface AccountStatement {
  fileName: string,
  translatedFileName: {
    en: string,
    fr: string,
  },
  id: string,
  referenceDate: string,
  signedUrl: string,
  state: string,
  type: string,
}

interface PageData {
  totalCount: number,
  currentCount: number
}

interface AggregatedAccountStatement {
  year: string,
  value: {
    month: string,
    accountStatements: AccountStatement[],
  }[],
}

export interface Account {
  id: string,
  type: string,
  custodianAccountNumber?: string,
}

interface AllLatestFormAgreements {
  id: string,
  type: string,
  translatedDisplayName?: {
    en: string,
    fr: string,
  },
  signedAt: string,
  signedUrl: string,
  scheduledTransfer: {
    state: string,
    subAccount: {
      account: Account,
    },
  } | null,
  user: {
    id: string,
  } | null,
  account: Account | null,
  subAccount: {
    id: string,
    goal: {
      name: string,
    },
    account: Account
  } | null,
  goal: {
    id: string,
    name: string,
  } | null,
}

interface FormattedFormAgreementsAccount {
  accountId: string,
  type: string,
  custodianAccountNumber?: string,
  accountForms?: {
    id: string,
    signedUrl: string,
    translatedDisplayName?: {
      en: string,
      fr: string,
    },
    signedAt: string
  }[],
  subAccountForms?: {
    id: string,
    signedUrl: string,
    goalName: string,
    translatedDisplayName?: {
      en: string,
      fr: string,
    },
    signedAt: string
  }[],
  scheduledTransferForms?: {
    id: string,
    signedUrl: string,
    translatedDisplayName?: {
      en: string,
      fr: string,
    },
    signedAt: string
  }[],
}

interface FormattedFormAgreements {
  userForms: {
    id: string,
    signedUrl: string,
    translatedDisplayName?: {
      en: string,
      fr: string,
    },
    signedAt: string
  }[],
  goalForms: {
    id: string,
    signedUrl: string,
    goalName: string,
    translatedDisplayName?: {
      en: string,
      fr: string,
    },
    signedAt: string
  }[],
  accounts: FormattedFormAgreementsAccount[],
}

interface UploadedDocuments {
  id: string,
  type: string,
  name?: string,
  fileName: string,
  uploadedAt: Date,
  downloadUrl: string,
  mediaType: string,
}

const Documents = ({ onClose }: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const perPage = 10;
  const { t } = useTranslation(['document']);
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const renderDocumentsPage = isFeatureEnabled(FeatureFlagTypes.DOCUMENTS);
  const maxHeight = renderDocumentsPage ? '60vh' : '330px';
  const style = {
    padding24: {
      padding: '0 24px !important',
    },
    noPadding: {
      padding: '0px 0px 0px 0px',
    },
    tabPanels: {
      padding: '10px 0 0 0',
      flexDirection: 'column',
      display: 'flex',
      overflowY: 'auto',
      maxHeight: isMobileScreen ? document.getElementsByTagName('body')[0].clientHeight - 230 : maxHeight,
    },
  };
  const accountStatementsTypes = [
    'MANAGEMENT_FEES_ANNUAL_REPORT',
    'ADMINISTRATIVE_FEES_ANNUAL_REPORT',
    'ANNUAL_PERFORMANCE_REPORT',
  ];

  if (availableFeatureFlagsVar().includes('CUSTODIAN_STATEMENT')) {
    accountStatementsTypes.push('MONTHLY_ACCOUNT_STATEMENTS');
  }
  if (availableFeatureFlagsVar().includes('ONEVEST_STATEMENT')) {
    accountStatementsTypes.push('OV_MONTHLY_ACCOUNT_STATEMENTS');
  }
  const taxStatementsTypes = [
    'TAX_SLIPS_LAST_10_MONTHS',
    'TAX_SLIPS_FIRST_60_DAYS',
    'TAX_SLIPS_T4RSP_RL2',
    'TAX_SLIPS_T4RIF_RL2',
    'TAX_SLIPS_T4A_RL1',
    'TAX_SLIPS_NR4',
    'TAX_SLIPS_T5008',
    'TAX_SLIPS_TRADING_SUMMARY',
    'TAX_SLIPS_T5_RL3',
    'TAX_SLIPS_T3_RL16',
    'TAX_SLIPS_T3_BREAKDOWN',
    'TAX_SLIPS_T5013_RL15',
  ];
  const uploadedDocumentsTypes = [
    'OTHER',
    'BANKING',
    'ID_VERIFICATION',
  ];
  const client = useApolloClient();
  const [init] = useState(true);
  const [value, setValue] = useState(1);
  const [file, setFile] = useState<File | null>();
  const [inputValue, setInputValue] = useState('');
  const [showFilterNumber, setShowFilterNumber] = useState(false);
  const [filterNumber, setFilterNumber] = useState(0);
  const [dateFilterSelected, setDateFilterSelected] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [dateRange, setDateRange] = useState<DateRange<Dayjs> | null>();
  const [openUploadedDocModal, setOpenUploadedDocModal] = useState(false);
  const [openAddDocModal, setOpenAddDocModal] = useState(false);
  const [docDownloadUrl, setDocDownloadUrl] = useState('');
  const [docTitle, setDocTitle] = useState('');
  const [docMediaType, setDocMediaType] = useState('');
  const [accountStatements, setAccountStatements] = useState<AggregatedAccountStatement[]>([]);
  const [loadAccountStatements, setLoadAccountStatements] = useState(false);
  const [loadTaxStatements, setLoadTaxStatements] = useState(false);
  const [taxStatements, setTaxStatements] = useState<AggregatedAccountStatement[]>([]);
  const [formAgreements, setFormAgreements] = useState<{source: FormattedFormAgreements, filtered: FormattedFormAgreements}>({
    source: {
      userForms: [],
      goalForms: [],
      accounts: [],
    },
    filtered: {
      userForms: [],
      goalForms: [],
      accounts: [],
    },
  });
  const [uploadedDocuments, setUploadedDocuments] = useState<{source: UploadedDocuments[], filtered: UploadedDocuments[]}>({
    source: [],
    filtered: [],
  });
  const [previewHtml, setPreviewHtml] = useState('');
  const [open, setOpen] = useState(false);
  const [statementsPageData, setStatementsPageData] = useState<PageData>({ totalCount: 0, currentCount: 0 });
  const [taxStatementsPageData, setTaxStatementsPageData] = useState<PageData>({ totalCount: 0, currentCount: 0 });
  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  const selectFile = (chosenFile: File) => {
    setFile(chosenFile);
    setInputValue(chosenFile.name);
  };

  const aggregateAccountStatements = (statements: AccountStatement[]): AggregatedAccountStatement[] => {
    const data: AggregatedAccountStatement[] = [];
    const years = new Set();
    statements.forEach((accountStatement) => {
      years.add(dayjs(accountStatement.referenceDate).toDate().getFullYear());
    });
    years.forEach((year) => {
      const aggregatedAccountStatement: AggregatedAccountStatement = {
        year: '',
        value: [],
      };
      const monthsMap = new Map<string, AccountStatement[]>([]);
      statements.forEach((item) => {
        const date = dayjs(item.referenceDate).toDate();
        const month = dayjs(date).format('MMMM');
        if (date.getFullYear() === year) {
          if (monthsMap.has(month)) {
            const array = monthsMap.get(month);
            if (array) {
              array.push(item);
              monthsMap.set(month, array);
            }
          } else {
            monthsMap.set(month, [item]);
          }
        }
      });
      aggregatedAccountStatement.year = year as string;
      monthsMap.forEach((item, key) => {
        aggregatedAccountStatement.value.push({ month: key, accountStatements: item });
      });
      data.push(aggregatedAccountStatement);
    });
    return data;
  };

  const mergeArray = (old: AggregatedAccountStatement[], resent: AggregatedAccountStatement[]): AggregatedAccountStatement[] => {
    const tempOld = [...old];
    resent.forEach((item) => {
      const found = tempOld.find((e) => item.year === e.year);
      if (found) {
        item.value.forEach((itemValue) => {
          const foundMonth = found.value.find((f) => f.month === itemValue.month);
          if (foundMonth) {
            foundMonth.accountStatements.push(...itemValue.accountStatements);
          } else {
            found.value.push(itemValue);
          }
        });
      } else {
        tempOld.push(item);
      }
    });
    return tempOld;
  };

  const setNonTaxAccountStatementsData = (data: {
    fetchAccountStatements: {
      accountStatements: AccountStatement[],
      totalCount: number,
    }
  }): void => {
    setStatementsPageData({
      currentCount: statementsPageData.currentCount + data.fetchAccountStatements.accountStatements.length,
      totalCount: data.fetchAccountStatements.totalCount,
    });
    const aggregate: AggregatedAccountStatement[] = aggregateAccountStatements(data.fetchAccountStatements.accountStatements);
    const newArray = mergeArray(accountStatements, aggregate);
    setAccountStatements([...newArray]);
    setLoadAccountStatements(false);
  };

  const setTaxStatementsData = (data: {
    fetchAccountStatements: {
      accountStatements: AccountStatement[],
      totalCount: number,
    }
  }): void => {
    setTaxStatementsPageData({
      currentCount: taxStatementsPageData.currentCount + data.fetchAccountStatements.accountStatements.length,
      totalCount: data.fetchAccountStatements.totalCount,
    });
    const aggregate: AggregatedAccountStatement[] = aggregateAccountStatements(data.fetchAccountStatements.accountStatements);
    setTaxStatements((prev) => [...prev, ...aggregate]);
    setLoadTaxStatements(false);
  };

  const getStatements = async (
    data: { types: string[], offset: number, beforeDate?: Date, afterDate?: Date, accountId?: string },
  ): Promise<ApolloQueryResult<any>> => {
    let filter: { types: string[], beforeDate?: string, afterDate?: string, accountId?: string} = { types: data.types };
    if (data.beforeDate && data.afterDate) {
      filter = { ...filter, beforeDate: formatDate(data.beforeDate), afterDate: formatDate(data.afterDate) };
    }
    if (data.accountId) {
      filter = { ...filter, accountId: data.accountId };
    }

    return client.query({
      query: FETCH_ACCOUNT_STATEMENTS,
      variables: {
        input: {
          filter,
          pagination: {
            offSet: data.offset,
            perPage,
          },
        },
      },
    });
  };

  const setFormattedFormAgreements = (forms?: AllLatestFormAgreements[]): FormattedFormAgreements => {
    if (!forms) {
      return {
        userForms: [],
        goalForms: [],
        accounts: [],
      };
    }

    const formattedFormAgreements = forms.reduce((acc: FormattedFormAgreements, formAgreement: AllLatestFormAgreements) => {
      if (!isNil(formAgreement.user)) {
        acc.userForms.push({
          id: formAgreement.id,
          signedUrl: formAgreement.signedUrl,
          translatedDisplayName: formAgreement.translatedDisplayName,
          signedAt: formAgreement.signedAt,
        });
      }

      if (!isNil(formAgreement.account)) {
        const accountIndex = acc.accounts.findIndex((accountItem) => accountItem.accountId === formAgreement.account?.id);
        if (accountIndex === -1) {
          acc.accounts.push({
            accountId: formAgreement.account.id,
            type: formAgreement.account.type,
            custodianAccountNumber: formAgreement.account.custodianAccountNumber,
            accountForms: [{
              id: formAgreement.id,
              signedUrl: formAgreement.signedUrl,
              translatedDisplayName: formAgreement.translatedDisplayName,
              signedAt: formAgreement.signedAt,
            }],
          });
        } else {
          if (isNil(acc.accounts[accountIndex].accountForms)) {
            acc.accounts[accountIndex].accountForms = [];
          }
          acc.accounts[accountIndex].accountForms?.push({
            id: formAgreement.id,
            signedUrl: formAgreement.signedUrl,
            translatedDisplayName: formAgreement.translatedDisplayName,
            signedAt: formAgreement.signedAt,
          });
        }
      }

      if (!isNil(formAgreement.subAccount)) {
        const accountIndex = acc.accounts.findIndex((accountItem) => accountItem.accountId === formAgreement.subAccount?.account.id);
        if (accountIndex === -1) {
          acc.accounts.push({
            accountId: formAgreement.subAccount.account.id,
            type: formAgreement.subAccount.account.type,
            custodianAccountNumber: formAgreement.subAccount.account.custodianAccountNumber,
            subAccountForms: [{
              id: formAgreement.id,
              signedUrl: formAgreement.signedUrl,
              goalName: formAgreement.subAccount.goal.name,
              translatedDisplayName: formAgreement.translatedDisplayName,
              signedAt: formAgreement.signedAt,
            }],
          });
        } else {
          if (isNil(acc.accounts[accountIndex].subAccountForms)) {
            acc.accounts[accountIndex].subAccountForms = [];
          }
          acc.accounts[accountIndex].subAccountForms?.push({
            id: formAgreement.id,
            signedUrl: formAgreement.signedUrl,
            goalName: formAgreement.subAccount.goal.name,
            translatedDisplayName: formAgreement.translatedDisplayName,
            signedAt: formAgreement.signedAt,
          });
        }
      }

      if (!isNil(formAgreement.scheduledTransfer)
        && formAgreement.scheduledTransfer.state !== 'CANCELED'
        && formAgreement.type === 'PAD_AGREEMENT'
        && isFeatureEnabled(FeatureFlagTypes.PAD_AGREEMENTS)
      ) {
        const accountIndex = acc.accounts.findIndex((accountItem) => (
          accountItem.accountId === formAgreement.scheduledTransfer?.subAccount.account.id
        ));
        if (accountIndex === -1) {
          acc.accounts.push({
            accountId: formAgreement.scheduledTransfer.subAccount.account.id,
            type: formAgreement.scheduledTransfer.subAccount.account.type,
            custodianAccountNumber: formAgreement.scheduledTransfer.subAccount.account.custodianAccountNumber,
            scheduledTransferForms: [{
              id: formAgreement.id,
              signedUrl: formAgreement.signedUrl,
              translatedDisplayName: formAgreement.translatedDisplayName,
              signedAt: formAgreement.signedAt,
            }],
          });
        } else {
          if (isNil(acc.accounts[accountIndex].scheduledTransferForms)) {
            acc.accounts[accountIndex].scheduledTransferForms = [];
          }
          acc.accounts[accountIndex].scheduledTransferForms?.push({
            id: formAgreement.id,
            signedUrl: formAgreement.signedUrl,
            translatedDisplayName: formAgreement.translatedDisplayName,
            signedAt: formAgreement.signedAt,
          });
        }
      }

      if (!isNil(formAgreement.goal)) {
        acc.goalForms.push({
          id: formAgreement.id,
          signedUrl: formAgreement.signedUrl,
          translatedDisplayName: formAgreement.translatedDisplayName,
          goalName: formAgreement.goal.name,
          signedAt: formAgreement.signedAt,
        });
      }
      return acc;
    }, {
      userForms: [],
      goalForms: [],
      accounts: [],
    });

    return formattedFormAgreements;
  };

  const setFormAgreementData = (me: {
    me: {
      user: {
        allLatestFormAgreements?: AllLatestFormAgreements[]
      }
    }
  }): void => {
    const formattedFormAgreements = setFormattedFormAgreements(me.me.user?.allLatestFormAgreements);
    setFormAgreements({
      source: formattedFormAgreements,
      filtered: formattedFormAgreements,
    });
  };

  const [fetchAllLatestFormAgreements] = useLazyQuery(FETCH_ALL_LATEST_FORM_AGREEMENT, {
    variables: {},
    onCompleted: (e) => setFormAgreementData(e),
    nextFetchPolicy: 'standby',
  });

  const [fetchUploadedDocuments] = useLazyQuery(FETCH_FILE_DOCUMENTS, {
    variables: {
      input: {
        filter: {
          types: uploadedDocumentsTypes,
        },
      },
    },

    onCompleted: (data) => setUploadedDocuments({
      source: data.fetchFileDocuments.fileDocuments,
      filtered: data.fetchFileDocuments.fileDocuments,
    }),
    nextFetchPolicy: 'standby',
  });
  const openInANewTab = (url: string): void => {
    if (window) {
      window.open(url, '_blank');
    }
  };
  const [fetchUploadedDocument, { loading }] = useLazyQuery(FETCH_FILE_DOCUMENT, {
    variables: {
      fileDocumentId: '',
    },
    nextFetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDocDownloadUrl(data.fetchFileDocument.fileDocument.downloadUrl);
      setDocTitle(data.fetchFileDocument.fileDocument.name ?? data.fileDocument.fileName);
      setDocMediaType(data.fetchFileDocument.fileDocument.mediaType);
      setOpenUploadedDocModal(true);
    },
  });

  const [fetchAccountStatement] = useLazyQuery(FETCH_ACCOUNT_STATEMENT, {
    variables: {
      accountStatementId: '',
    },
    nextFetchPolicy: 'network-only',
    onCompleted: (data) => {
      openInANewTab(data.fetchAccountStatement.accountStatement.signedUrl);
    },
  });

  const setInitialValue = () : number => {
    if (isFeatureEnabled(FeatureFlagTypes.VIEW_STATEMENTS)) return 1;
    if (isFeatureEnabled(FeatureFlagTypes.VIEW_TAX_DOCUMENTS)) return 2;
    if (isFeatureEnabled(FeatureFlagTypes.VIEW_AGREEMENTS)) return 3;
    return 0;
  };

  useEffect(() => {
    getStatements({ types: accountStatementsTypes, offset: 0 }).then((result) => setNonTaxAccountStatementsData(result.data));
    getStatements({ types: taxStatementsTypes, offset: 0 }).then((result) => setTaxStatementsData(result.data));
    fetchAllLatestFormAgreements().then();
    if (renderDocumentsPage) fetchUploadedDocuments().then();
    setValue(setInitialValue());
  }, [init]);

  const previewAgreementClick = async (id: string, url?: string): Promise<void> => {
    const result = await client.query({
      query: RENDER_FORM_AGREEMENT,
      variables: {
        input: {
          formAgreementId: id,
        },
      },
    });
    if (result.data && result.data.renderFormAgreement.html) {
      setPreviewHtml(result.data.renderFormAgreement.html);
      setOpen(true);
    } else if (url) {
      const allAgreements = await fetchAllLatestFormAgreements();
      const foundAgreement = allAgreements.data.me.user.allLatestFormAgreements.find((agreement: { id: string; }) => agreement.id === id);
      if (foundAgreement) {
        openInANewTab(foundAgreement.signedUrl);
      }
    }
  };

  const previewUploadedDocument = async (doc: UploadedDocuments) => {
    const response = await fetchUploadedDocument({
      variables: {
        fileDocumentId: doc.id,
      },
    });
  };

  const handleClose = (): void => {
    setPreviewHtml('');
    setOpen(false);
  };

  const handleCloseAddDocModal = (): void => {
    setOpenAddDocModal(false);
    setFile(null);
    setInputValue('');
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendAnalyticsEvent(ovAnalyticsEvents.addDocumentModalContinueButtonSelect).then();
    if (file) {
      const createFileInput: CreateFileInput = {
        objectType: FileDocumentObjectTypes.USER,
        objectId: userIdVar(),
        fileName: file.name,
        type: FileDocumentTypes.OTHER,
        name: inputValue || file.name,
        mediaType: file.type,
      };

      const fileUploaderInput: FileUploaderProps = {
        createFileInput,
        uploadingFile: file,
        apolloClient: client,
        onSuccess: () => {
          fetchUploadedDocuments().then();
          handleCloseAddDocModal();
        },
      };

      await fileUploader(fileUploaderInput);
    }
  };

  const handleAccountStatementScroll = async (): Promise<void> => {
    if ((statementsPageData.currentCount < statementsPageData.totalCount) && !loadAccountStatements) {
      setLoadAccountStatements(true);
      const result = await getStatements({ types: accountStatementsTypes, offset: statementsPageData.currentCount });
      if (result.data) {
        setNonTaxAccountStatementsData(result.data);
      }
    }
  };

  const handleTaxStatementScroll = async (): Promise<void> => {
    if ((taxStatementsPageData.currentCount < taxStatementsPageData.totalCount) && !loadTaxStatements) {
      setLoadTaxStatements(true);
      const result = await getStatements({ types: taxStatementsTypes, offset: taxStatementsPageData.currentCount });
      if (result.data) {
        setTaxStatementsData(result.data);
      }
    }
  };

  const handleOpenFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (anchorEl) {
      setAnchorEl(null);
      if (filterNumber === 0) {
        setDateFilterSelected('');
        setSelectedAccount('');
        setDateRange(null);
      }
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const clearFilters = () => {
    if (showFilterNumber) {
      getStatements({ types: accountStatementsTypes, offset: 0 }).then((result) => setNonTaxAccountStatementsData(result.data));
      getStatements({ types: taxStatementsTypes, offset: 0 }).then((result) => setTaxStatementsData(result.data));
      setFormAgreements({ ...formAgreements, filtered: formAgreements.source });
      setUploadedDocuments({ ...uploadedDocuments, filtered: uploadedDocuments.source });
    }
    setAnchorEl(null);
    setDateFilterSelected('');
    setSelectedAccount('');
    setDateRange(null);
    setFilterNumber(0);
    setShowFilterNumber(false);
  };

  const applyFilters = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendAnalyticsEvent(ovAnalyticsEvents.documentsPageApplyFilterSelect).then();
    const filters = [];
    if (dateFilterSelected && dateRange) {
      filters.push(dateFilterSelected);
      setUploadedDocuments({
        ...uploadedDocuments,
        filtered: uploadedDocuments.source.filter((doc) => (
          dateRange[0]?.isBefore(dayjs(doc.uploadedAt)) && dateRange[1]?.isAfter(dayjs(doc.uploadedAt))
        )),
      });
    }

    if (selectedAccount) filters.push(selectedAccount);

    if (filters.length !== 0) {
      setFilterNumber(filters.length);
      setShowFilterNumber(true);
      getStatements({
        types: accountStatementsTypes,
        offset: 0,
        ...(dateFilterSelected && dateRange ? {
          beforeDate: dateRange[1]!.toDate(),
          afterDate: dateRange[0]!.toDate(),
        } : {}),
        ...(selectedAccount ? {
          accountId: selectedAccount,
        } : {}),
      }).then((result) => setNonTaxAccountStatementsData(result.data));

      getStatements({
        types: taxStatementsTypes,
        offset: 0,
        ...(dateFilterSelected && dateRange ? {
          beforeDate: dateRange[1]!.toDate(),
          afterDate: dateRange[0]!.toDate(),
        } : {}),
        ...(selectedAccount ? {
          accountId: selectedAccount,
        } : {}),
      }).then((result) => setTaxStatementsData(result.data));

      const formsFilteredByDate = dateFilterSelected && dateRange ? {
        userForms: formAgreements.source.userForms.filter((form) => (
          dateRange[0]?.isBefore(dayjs(form.signedAt)) && dateRange[1]?.isAfter(dayjs(form.signedAt))
        )),
        goalForms: formAgreements.source.goalForms.filter((form) => (
          dateRange[0]?.isBefore(dayjs(form.signedAt)) && dateRange[1]?.isAfter(dayjs(form.signedAt))
        )),
        accounts: formAgreements.source.accounts.reduce((acc: FormattedFormAgreementsAccount[], cur: FormattedFormAgreementsAccount) => {
          const accountForms = cur.accountForms?.filter((form) => (
            dateRange[0]?.isBefore(dayjs(form.signedAt)) && dateRange[1]?.isAfter(dayjs(form.signedAt))
          ));
          const subAccountForms = cur.subAccountForms?.filter((form) => (
            dateRange[0]?.isBefore(dayjs(form.signedAt)) && dateRange[1]?.isAfter(dayjs(form.signedAt))
          ));
          const scheduledTransferForms = cur.scheduledTransferForms?.filter((form) => (
            dateRange[0]?.isBefore(dayjs(form.signedAt)) && dateRange[1]?.isAfter(dayjs(form.signedAt))
          ));
          if (accountForms?.length || subAccountForms?.length || scheduledTransferForms?.length) {
            const account = {
              ...cur,
              accountForms,
              subAccountForms,
              scheduledTransferForms,
            };
            acc.push(account);
          }

          return acc;
        }, []),
      } : formAgreements.source;

      const filteredFormAgreements: FormattedFormAgreements = {
        ...(selectedAccount ? {
          userForms: [],
          goalForms: [],
          accounts: formsFilteredByDate.accounts.filter((account) => account.accountId === selectedAccount),
        } : formsFilteredByDate),
      };
      setFormAgreements({ ...formAgreements, filtered: filteredFormAgreements });
    }

    setAnchorEl(null);
  };
  if (openUploadedDocModal) {
    return (
      <FlowModal
        open={openUploadedDocModal}
        showCloseButton
        maintainModal
        onClose={() => setOpenUploadedDocModal(false)}
        component={(
          <Box sx={{ overflowWrap: 'break-word' }}>
            <Typography variant="heading2" sx={{ mb: 1 }}>{docTitle}</Typography>
            {loading ? (
              <Box
                sx={{
                  width: '100%',
                  height: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : undefined}
            {!loading ? (
              <Box sx={{ width: '100%', minHeight: '200px' }}>
                {docMediaType === 'application/pdf' ? (
                  <object data={docDownloadUrl} type="application/pdf" aria-label={docTitle} height="500px" style={{ width: '100%' }}>
                    <Box
                      sx={{
                        width: '100%',
                        minHeight: '400px',
                        backgroundColor: colors.grayBackground,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          width: '220px',
                          minHeight: '140px',
                          padding: 1,
                          borderRadius: '8px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: colors.ovWhite,
                          gap: '12px',
                          cursor: 'pointer',
                        }}
                        onClick={() => window.open(docDownloadUrl, '_blank')}
                      >
                        <DescriptionIcon fontSize="large" />
                        <Box sx={{ width: '180px', overflowWrap: 'break-word' }}>
                          <Typography variant="bodySub3">
                            {docTitle}
                          </Typography>
                        </Box>
                        <Typography variant="captionDescriptorBold">{t('document:download')}</Typography>
                      </Box>
                    </Box>
                  </object>
                ) : (
                  <img src={docDownloadUrl} alt={docTitle} style={{ width: '100%' }} />
                )}
              </Box>
            ) : undefined}
          </Box>
        )}
      />
    );
  }

  if (openAddDocModal) {
    return (
      <FlowModal
        open={openAddDocModal}
        showCloseButton
        maintainModal
        onClose={handleCloseAddDocModal}
        component={(
          <>
            <OvForm
              formsStyle={{ maxWidth: 416, marginBottom: 8 }}
              buttonText={t('document:addDocumentModal.button.upload')}
              disableButton={!file}
              onSubmit={onSubmit}
            >
              <Typography variant="heading2">{t('document:addDocumentModal.title')}</Typography>
              <Typography variant="paragraphMain" sx={{ mb: 4 }}>{t('document:addDocumentModal.subText')}</Typography>
              <Box sx={{ mb: 1 }}>
                <Typography variant="captionDescriptorMedium" sx={{ color: colors.secondary, fontWeight: 500 }}>{t('document:addDocumentModal.selectFile')}</Typography>
              </Box>
              <DroppableFileInput
                onFileChosen={(chosenFile) => selectFile(chosenFile)}
                fileMaxSize={20}
                acceptedFiles={[FileTypes.PDF, FileTypes.JPEG, FileTypes.JPG, FileTypes.PNG]}
              />
              <SimpleInput
                label={t('document:addDocumentModal.inputLabel')}
                inputProps={{ placeholder: t('document:addDocumentModal.inputPlaceholder') }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onClear={() => setInputValue('')}
              />
            </OvForm>
            <Button
              fullWidth
              onClick={handleCloseAddDocModal}
            >
              {t('document:addDocumentModal.button.cancel')}
            </Button>
          </>
        )}
      />
    );
  }

  if (open) {
    return (
      <DialogPopUp
        content={previewHtml}
        handleClose={handleClose}
        open={open}
      />
    );
  }

  if (!!anchorEl && isMobileScreen) {
    return (
      <FlowModal
        open={!!anchorEl}
        showCloseButton
        maintainModal
        onClose={() => {
          setAnchorEl(null);
          if (filterNumber === 0) {
            setDateFilterSelected('');
            setSelectedAccount('');
            setDateRange(null);
          }
        }}
        component={(
          <FilterDocuments
            applyFilters={applyFilters}
            clearFilters={clearFilters}
            dateFilterSelected={dateFilterSelected}
            dateRange={dateRange}
            selectedAccount={selectedAccount}
            setDateFilterSelected={setDateFilterSelected}
            setSelectedAccount={setSelectedAccount}
            setDateRange={setDateRange}
          />
        )}
      />
    );
  }

  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Box sx={styles.detailBox}>
            <Typography variant="heading2" sx={{ marginBottom: '8px' }}>{t('document:header')}</Typography>
            {renderDocumentsPage && (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ position: 'relative' }}>
                  {showFilterNumber && <Box sx={styles.filterNumber}>{filterNumber}</Box>}
                  <ToggleButton value="filter" selected={!!anchorEl} onChange={(e) => handleOpenFilter(e)} sx={styles.filterButton}>
                    <img src={FilterIcon} alt={t('document:button.filter')} />
                  </ToggleButton>
                  <Popover
                    open={!!anchorEl && !isMobileScreen}
                    onClose={handleOpenFilter}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{ backgroundColor: 'transparent' }}
                  >
                    <FilterDocuments
                      applyFilters={applyFilters}
                      clearFilters={clearFilters}
                      dateFilterSelected={dateFilterSelected}
                      dateRange={dateRange}
                      selectedAccount={selectedAccount}
                      setDateFilterSelected={setDateFilterSelected}
                      setSelectedAccount={setSelectedAccount}
                      setDateRange={setDateRange}
                    />
                  </Popover>
                </Box>
                <Button
                  variant="secondary-small"
                  onClick={() => {
                    sendAnalyticsEvent(ovAnalyticsEvents.documentsPageAddDocumentSelect).then();
                    setOpenAddDocModal(true);
                  }}
                >
                  {isMobileScreen ? t('document:button.uploadDocumentsMobile') : t('document:button.uploadDocuments')}
                </Button>
              </Box>
            )}
          </Box>
          <Container className="home-page" sx={styles.depositInnerContainer}>
            <TabContext value={value.toString()}>
              <Box sx={{ borderBottom: 1, ...renderDocumentsPage ? { border: 'none', overflow: 'auto' } : { borderColor: 'divider' } }}>
                <TabList onChange={handleChange} variant={renderDocumentsPage ? undefined : 'fullWidth'} centered aria-label="dashboard tabs" sx={renderDocumentsPage ? { width: 550 } : {}}>
                  {isFeatureEnabled(FeatureFlagTypes.VIEW_STATEMENTS) && (
                    <Tab
                      id="tab-1"
                      label={
                        (
                          <span>
                            {t('document:tabs.statementsTab.title')}
                          </span>
                        )
                      }
                      value="1"
                    />
                  )}
                  {isFeatureEnabled(FeatureFlagTypes.VIEW_TAX_DOCUMENTS) && (
                    <Tab
                      id="tab-2"
                      label={
                        (
                          <span>
                            {t('document:tabs.taxDocumentsTab.title')}
                          </span>
                        )
                      }
                      value="2"
                    />
                  )}
                  {isFeatureEnabled(FeatureFlagTypes.VIEW_AGREEMENTS) && (
                    <Tab
                      id="tab-3"
                      label={
                        (
                          <span>
                            {t('document:tabs.agreementsTab.title')}
                          </span>
                        )
                      }
                      value="3"
                    />
                  )}
                  {renderDocumentsPage && (
                    <Tab
                      id="tab-4"
                      label={
                        (
                          <span>
                            {t('document:tabs.uploadedDocuments.title')}
                          </span>
                        )
                      }
                      value="4"
                    />
                  )}
                </TabList>
              </Box>
              <TabPanel id="panel-1" aria-labelledby="panel-1" value="1" sx={style.noPadding}>
                <OvPagination
                  nextPage={handleAccountStatementScroll}
                  sx={style.tabPanels}
                  loading={loadAccountStatements}
                  callNextPage={(statementsPageData.currentCount < statementsPageData.totalCount) && !loadAccountStatements}
                >
                  {
                    accountStatements.map((accountStatement) => (
                      accountStatement.value.map((item) => (
                        <Box key={item.accountStatements[0].id}>
                          <Typography key={item.month} variant="bodyMain3" style={{ marginBottom: 16, color: colors.neutral30 }}>
                            {`${item.month} ${accountStatement.year}`}
                          </Typography>
                          {
                            item.accountStatements.map((account) => (
                              <Box key={account.id} mt={2} mb={1}>
                                <Typography
                                  onClick={() => {
                                    fetchAccountStatement({
                                      variables: {
                                        accountStatementId: account.id,
                                      },
                                    });
                                  }}
                                  key={account.referenceDate}
                                  variant="bodyMain3"
                                  sx={{ cursor: 'pointer' }}
                                >
                                  {translateBackend(account.translatedFileName)}
                                </Typography>
                                <Box key={account.id} mt={1} />
                                <Divider />
                              </Box>
                            ))
                          }
                        </Box>
                      ))
                    ))
                  }
                </OvPagination>
              </TabPanel>
              <TabPanel id="panel-2" aria-labelledby="panel-2" value="2" sx={style.noPadding}>
                <OvPagination
                  nextPage={handleTaxStatementScroll}
                  sx={style.tabPanels}
                  loading={loadTaxStatements}
                  callNextPage={(taxStatementsPageData.currentCount < taxStatementsPageData.totalCount) && !loadTaxStatements}
                >
                  {
                    taxStatements.map((accountStatement) => (
                      accountStatement.value.map((item, index) => (
                        <Box key={item.accountStatements[0].id}>
                          <Typography key={item.month} variant="bodyMain3" style={{ marginBottom: 16, color: colors.neutral30 }}>{`${item.month} ${accountStatement.year}`}</Typography>
                          {
                            item.accountStatements.map((account) => (
                              <Box key={account.id} mt={2} mb={1}>
                                <Typography onClick={() => openInANewTab(account.signedUrl)} key={account.referenceDate} variant="bodyMain3" sx={{ marginBottom: 2, cursor: 'pointer' }}>
                                  {account.fileName}
                                </Typography>
                                <Box key={account.id} mt={1} />
                                <Divider />
                              </Box>
                            ))
                          }
                        </Box>
                      ))
                    ))
                  }
                </OvPagination>
              </TabPanel>
              <TabPanel id="panel-3" aria-labelledby="panel-3" value="3" sx={style.tabPanels}>
                {
                  formAgreements?.filtered?.userForms.map((item) => (
                    <Box key={Math.random()} mt={2} mb={1}>
                      <Typography key={item.id} variant="bodyMain3" onClick={() => previewAgreementClick(item.id, item.signedUrl)} sx={{ marginBottom: 2, cursor: 'pointer' }}>{translateBackend(item.translatedDisplayName)}</Typography>
                      <Box key={Math.random()} mt={1} />
                      <Divider />
                    </Box>
                  ))
                }
                {
                  formAgreements?.filtered?.goalForms.map((item) => (
                    <Box key={Math.random()} mt={2} mb={1}>
                      <Typography key={item.id} onClick={() => previewAgreementClick(item.id, item.signedUrl)} variant="bodyMain3" sx={{ marginBottom: 2, cursor: 'pointer' }}>{`${translateBackend(item.translatedDisplayName)} - ${item.goalName}`}</Typography>
                      <Box key={Math.random()} mt={1} />
                      <Divider />
                    </Box>
                  ))
                }
                {
                  formAgreements?.filtered?.accounts.map((item) => (
                    <div key={item.type} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography key={item.type} variant="bodyMain3" style={{ marginBottom: 16, marginTop: 10, color: colors.neutral30 }}>{allAccountTypesVar().find((a) => a.key === item.type)?.title ?? ''}</Typography>
                      {
                        item.accountForms?.map((e) => (
                          <Box key={Math.random()} mt={2} mb={1}>
                            <Typography key={e.id} variant="bodyMain3" sx={{ marginBottom: 2, cursor: 'pointer' }} onClick={() => previewAgreementClick(e.id, e.signedUrl)}>{translateBackend(e.translatedDisplayName)}</Typography>
                            <Box key={Math.random()} mt={1} />
                            <Divider />
                          </Box>
                        ))
                      }
                      {
                        item.subAccountForms?.map((sAgreement) => (
                          <Box key={Math.random()} mt={2} mb={1}>
                            <Typography key={sAgreement.id} onClick={() => previewAgreementClick(sAgreement.id, sAgreement.signedUrl)} variant="bodyMain3" sx={{ marginBottom: 2, cursor: 'pointer' }}>{`${translateBackend(sAgreement.translatedDisplayName)} - ${sAgreement.goalName}`}</Typography>
                            <Box key={Math.random()} mt={1} />
                            <Divider />
                          </Box>
                        ))
                      }
                      {
                        item.scheduledTransferForms?.map((padItem) => (
                          <Box key={Math.random()} mt={2} mb={1}>
                            <Typography key={padItem.id} variant="bodyMain3" onClick={() => previewAgreementClick(padItem.id, padItem.signedUrl)} sx={{ marginBottom: 2, cursor: 'pointer' }}>{translateBackend(padItem.translatedDisplayName)}</Typography>
                            <Box key={Math.random()} mt={1} />
                            <Divider />
                          </Box>
                        ))
                      }
                    </div>
                  ))
                }
              </TabPanel>
              {renderDocumentsPage && (
                <TabPanel id="panel-4" aria-labelledby="panel-4" value="4" sx={style.tabPanels}>
                  {uploadedDocuments.filtered.map((doc) => (
                    <Box key={Math.random()} mt={2} mb={1}>
                      <Box
                        key={doc.id}
                        onClick={() => previewUploadedDocument(doc)}
                        sx={{
                          display: 'flex', alignItems: 'end', marginBottom: 2, cursor: 'pointer',
                        }}>
                        <Typography key={Math.random()} variant="bodySub2" sx={{ width: 104, mr: 1, color: colors.neutral30 }}>{dayjs(doc.uploadedAt).format('MMMM YYYY')}</Typography>
                        <Typography key={Math.random()} variant="bodyMain3">{doc.name ?? doc.fileName}</Typography>
                      </Box>
                      <Box key={Math.random()} mt={1} />
                      <Divider />
                    </Box>
                  ))}
                </TabPanel>
              )}
            </TabContext>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
export default Documents;
